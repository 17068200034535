import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Maintenance} from "../components/Maintenance";

class RouterApp extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/" exact component={Maintenance} />
                </Switch>
            </Router>
        );
    }
}

export default RouterApp;
