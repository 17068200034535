import React, {useState, useEffect} from "react";
import "../../App.css";
import {HouskeeperService} from "@common/housekeeper-api";
import {authManager} from "@common/authentication";
import { Input} from "@vacasa/react-components-lib";

interface GuestWork {
    name: string;
    phone: string;
    email: string;
}

export const Maintenance = () => {
    const token = authManager.getJwt();
    const houskeeperService = new HouskeeperService(token);
    const [isLoading, setIsLoading] = useState(true);
    const [guestWorksHostContact, setguestWorksHostContact] = useState<GuestWork>({name: "", phone: "", email: ""});
    const info = authManager.getInfoFromAdmin<any>();

    const getData = async (unitId: number) => {
        const gwStaffer = await houskeeperService.getGwUnitStaffers(unitId);
        if(gwStaffer.data?.length > 0){
            setguestWorksHostContact(gwStaffer.data[0]?.attributes as GuestWork);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getData(info.unitId);
    },[]);

    //resize iframe for each render.
    useEffect(() => {
        const height = document.getElementById("root").clientHeight;
        window.parent.postMessage({action: `${height}px`, type: "resize"}, "*");
    });

    return (
        <div className="summaryApp">
            {isLoading ? (
                <div className="row">
                    <div className={`col centerContent}`}>
                        <h6>Loading data...</h6>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="row">
                        <div className={"col-3 col-margin"}>
                            <Input
                                label="GuestWorks Host Contact"
                                value={guestWorksHostContact?.name}
                                onChange={() => {}}
                                disabled={true}
                                type="text"
                                customClass="input-style"
                            />
                            <div style={{fontSize: "13px", marginTop: "5px"}}>
                                <b style={{float: "left"}}>Phone:&nbsp;</b>
                                <div style={{display: "inline-block"}}>{guestWorksHostContact?.phone}</div>
                            </div>
                            <div style={{fontSize: "13px"}}>
                                <b style={{float: "left"}}>Email:&nbsp;</b>
                                <div style={{display: "grid"}}>{guestWorksHostContact?.email}</div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
