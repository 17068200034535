import React from "react";
import "./App.css";
import {ThemeProvider} from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import RouterApp from "./router/Router";
import {Toaster} from "react-hot-toast";

function App() {
    return (
        <div>
            <ThemeProvider theme={_default}>
                <Toaster position="top-right" reverseOrder={false} />
                <RouterApp />
            </ThemeProvider>
        </div>
    );
}

export default App;
